import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'cloture',
    loadChildren: () => import('./closing/closing.module').then(m => m.ClosingModule)
  },
  {
    path: 'statut',
    loadChildren: () => import('./status/status.module').then(m => m.StatusModule)
  }
];
